/* eslint-disable complexity */

/* eslint-disable import/order */
const React = require('react');
const PropTypes = require('prop-types');
const serialize = require('serialize-javascript');

const noop = require('lodash/noop');
const { loadable } = require('nordic/lazy');
const Style = require('nordic/style');
const Script = require('nordic/script');
const { Head: NordicHead } = require('nordic/head');
const GTM = require('nordic/gtm');
const Hotjar = require('nordic/hotjar');
const MelidataTrack = require('nordic/melidata/melidata-track');

const { TrackingProvider } = require('@pog-ui-landings-library/framework');

const BusinessTools = loadable(async () =>
  import('@pog-ui-landings-library/business-tool'),
);
const CardList = loadable(async () =>
  import('@pog-ui-landings-library/card-list'),
);
const Faqs = loadable(async () => import('@pog-ui-landings-library/faqs'));
const FilteredCards = loadable(async () =>
  import('@pog-ui-landings-library/filtered-cards'),
);
const GenericComponent = loadable(async () =>
  import('@pog-ui-landings-library/generic-component'),
);
const Hero = loadable(async () => import('@pog-ui-landings-library/hero'));
const Payments = loadable(async () =>
  import('@pog-ui-landings-library/payments'),
);
const Taxes = loadable(async () => import('@pog-ui-landings-library/taxes'));
const Tyc = loadable(async () => import('@pog-ui-landings-library/tyc'));
const TycSection = loadable(async () =>
  import('@pog-ui-landings-library/tyc-section'),
);

const CardListSection = require('@pog-ui-landings-library/card-list-section');
const CardsMediaSection = require('@pog-ui-landings-library/cards-media-section');
const FaqsSection = require('@pog-ui-landings-library/faqs-section');
const FeesSection = require('@pog-ui-landings-library/fees-section');
const FixedActionsSection = require('@pog-ui-landings-library/fixed-actions-section');
const FormSection = require('@pog-ui-landings-library/form-section');
const Head = require('@pog-ui-landings-library/head');
const HeroSection = require('@pog-ui-landings-library/hero-section');
const ImageTextSection = require('@pog-ui-landings-library/image-text-section');
const ListSection = require('@pog-ui-landings-library/list-section');
const MultiproductWrapper = require('@pog-ui-landings-library/multiproduct-wrapper');
const NavBar = require('@pog-ui-landings-library/navbar');
const NavBarSection = require('@pog-ui-landings-library/navbar-section');
const Opportunity = require('@pog-ui-landings-library/opportunity');
const PaymentMethods = require('@pog-ui-landings-library/payment-methods');
const PaymentMethodsSection = require('@pog-ui-landings-library/payment-methods-section');
const PreFooterSection = require('@pog-ui-landings-library/prefooter-section');
const NewPricing = require('@calm-landings-library/pricing');
const RemoteModule = require('@pog-ui-landings-library/remote-module'); // es-lint-disable-line: import/no-unresolved
const StandardGenericSection = require('@pog-ui-landings-library/standard-generic-section');
const ShortcutsSection = require('@pog-ui-landings-library/shortcuts-section');
const TaxesHybrid = require('@pog-ui-landings-library/taxes-hybrid');
const TitleOnlySection = require('@pog-ui-landings-library/title-only-section');
const SgsPaymentMethods = require('@calm-landings-library/sgs-payment-methods');
const SgsImagesHighlight = require('@calm-landings-library/sgs-images-highlight');
const SgsAnimationComponent = require('@calm-landings-library/sgs-animation-component');
const SgsSlider = require('@calm-landings-library/sgs-slider');
const SgsAccordionSlider = require('@calm-landings-library/sgs-accordion-slider');
const SgsCardPlan = require('@calm-landings-library/sgs-card-plan');
const SgsHero = require('@calm-landings-library/sgs-hero');
const SgsSpecs = require('@calm-landings-library/sgs-specs');
const SgsMultiproductSelector = require('@calm-landings-library/sgs-multiproduct-selector');
const SgsGenericHero = require('@calm-landings-library/sgs-generic-hero');
const SgsDeviceList = require('@calm-landings-library/sgs-device-list');
const SgsMediaSlider = require('@calm-landings-library/sgs-media-slider');
const SgsMessageBand = require('@calm-landings-library/sgs-message-band');

const lazyWrapper = require('../../utils/lazy-wrapper');
const {
  MELIDATA_MODE_SEND,
  MELIDATA_MODE_CLEAN_SEND,
} = require('../../utils/constants');
const { handleTrackMelidata, handleTrackGTM } = require('../../utils/tracking');

const DEPRECATED_SECTIONS = ['pricing'];

const View = ({
  experiments,
  imagesPrefix,
  isMobile,
  isWebView,
  landingConfig: {
    groupId,
    variantId,
    landingVersionId: versionId,
    variantConfigurationId,
    variantName,
    head,
    productName,
    tracking: { gtm, melidata, hotjar },
    theme,
  },
  locale,
  device,
  mainStyles,
  scope,
  sections,
  initCallback,
  landingStatus,
  isPreview,
  recaptchaSiteKey,
}) => {
  const getSectionName = (type) => {
    const genericComponentItems = [
      'paymentsteps',
      'moneyout',
      'prepaid',
      'kitqr',
      'credits',
      'security',
    ];

    if (genericComponentItems.includes(type)) {
      return 'generic-component';
    }

    return type;
  };

  const uniqueSections = Array.from(
    new Set(
      sections.map((section) => getSectionName(section.type.toLowerCase())),
    ),
  );
  const hasRemoteModules = sections.some(
    ({ type }) => type.toLowerCase() === 'remote-module',
  );

  const trackHandle = {
    notifyTrack: (trackData, mode) => {
      try {
        const trackMode =
          mode === MELIDATA_MODE_CLEAN_SEND ? mode : MELIDATA_MODE_SEND;
        const { melidata: melidataFn } = window || {};

        if (melidataFn) {
          melidataFn(trackMode, 'event', trackData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    notifyMultipleTracks: (trackData) => {
      try {
        handleTrackMelidata(trackData.melidata);
        handleTrackGTM(trackData.gtm, gtm);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  };

  return (
    <main
      id="s-landing"
      className="s-landing"
      style={mainStyles}
      data-group-id={groupId}
      data-variant-id={variantId}
      data-version-id={versionId}
      data-variant-configuration-id={variantConfigurationId}
      data-variant-name={variantName}
      data-product-name={productName}
      ref={initCallback}
    >
      <Style
        href={isMobile ? 'landingMobile.css' : 'landingDesktop.css'}
        critical
      />

      {uniqueSections
        .filter((name) => !DEPRECATED_SECTIONS.includes(name))
        .map((section) => {
          const noThemedSections = [
            'new-pricing',
            'sgs-accordion-slider',
            'sgs-animation-component',
            'sgs-card-plan',
            'sgs-device-list',
            'sgs-generic-hero',
            'sgs-hero',
            'sgs-images-highlight',
            'sgs-media-slider',
            'sgs-message-band',
            'sgs-multiproduct-selector',
            'sgs-payment-methods',
            'sgs-slider',
            'sgs-specs',
            'sgs-vanilla',
          ];

          if (noThemedSections.includes(section)) {
            return (
              <Style
                key={section}
                href={
                  isMobile ? `${section}-mobile.css` : `${section}-desktop.css`
                }
                critical
              />
            );
          }

          return (
            <Style
              key={section}
              href={
                isMobile
                  ? `${section}-${theme}-mobile.css`
                  : `${section}-${theme}-desktop.css`
              }
              critical
            />
          );
        })}

      <NordicHead>
        <Head
          {...head}
          title={`${scope && !/^(prod).+/i.test(scope) ? `${scope} - ` : ''}${
            head.title
          }`}
        />

        <link
          href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2"
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2"
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <Style>
          {`@font-face {
              font-family:'Proxima Nova';
              font-weight:800;
              font-display:swap;
              font-style:normal;
              src:url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2) format("woff2"),
                  url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff) format("woff"),
                  url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.ttf) format("truetype")
            }
            @font-face {
              font-family:'Proxima Nova';
              font-weight:700;
              font-display:swap;
              font-style:normal;
              src:url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2) format("woff2"),
                  url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff) format("woff"),
                  url(https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.ttf) format("truetype")
            }`}
        </Style>
      </NordicHead>

      <div className="s-sections">
        <TrackingProvider trackHandler={trackHandle}>
          {sections?.map((section) => {
            const { id, config, type } = section;
            const { lazyMode, anchor, id: configId } = config || {};
            const key = `${type}__${id}`;
            // FIXME: Homogenize alias in data to follow sections' schemas (They declare id as attribute).
            const sectionID = configId || anchor || type;

            if (config?.form?.recaptcha) {
              const recaptchaConfig = config.form.recaptcha;
              const recaptcha =
                typeof recaptchaConfig === 'object' ? recaptchaConfig : {};

              config.form = {
                ...(config.form || {}),
                recaptcha: {
                  ...recaptcha,
                  key: recaptcha.key || recaptchaSiteKey,
                },
              };
            }

            switch (getSectionName(type.toLowerCase())) {
              case 'standard-generic-section':
                return (
                  <StandardGenericSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'form-section':
                return (
                  <FormSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'faqs-section':
                return (
                  <FaqsSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'fixed-actions-section':
                return (
                  <FixedActionsSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'fees-section':
                return (
                  <FeesSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'image-text-section':
                return (
                  <ImageTextSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'list-section':
                return (
                  <ListSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'payment-methods-section':
                return (
                  <PaymentMethodsSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'prefooter-section':
                return (
                  <PreFooterSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'navbar-section':
                return (
                  <NavBarSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'tyc-section':
                return lazyWrapper(
                  <TycSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'title-only-section':
                return (
                  <TitleOnlySection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'faqs':
                return lazyWrapper(
                  <Faqs
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'hero':
                return lazyWrapper(
                  <Hero
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'hero-section':
                return (
                  <HeroSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />
                );
              case 'shortcuts-section':
                return (
                  <ShortcutsSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'opportunity':
                return (
                  <Opportunity
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'payments':
                return lazyWrapper(
                  <Payments
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'taxes':
                return lazyWrapper(
                  <Taxes
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'tyc':
                return lazyWrapper(
                  <Tyc
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'business-tool':
                return lazyWrapper(
                  <BusinessTools
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'multiproduct-wrapper':
                return lazyWrapper(
                  <MultiproductWrapper
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'payment-methods':
                return lazyWrapper(
                  <PaymentMethods
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'taxes-hybrid':
                return lazyWrapper(
                  <TaxesHybrid
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'navbar':
                return (
                  <NavBar
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />
                );
              case 'new-pricing':
                return lazyWrapper(
                  <NewPricing
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'remote-module': {
                const { remoteModule } = config;

                if (!remoteModule) {
                  return null;
                }

                const { name, host, moduleSetup } = remoteModule;

                if (!name || !host) {
                  return null;
                }

                return (
                  <RemoteModule
                    key={key}
                    {...remoteModule}
                    id={sectionID}
                    isMobile={isMobile}
                    data={moduleSetup}
                    moduleId={key}
                    isWebView={isWebView}
                  />
                );
              }
              case 'generic-component':
                return lazyWrapper(
                  <GenericComponent
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  config?.video ? 'none' : lazyMode,
                );
              case 'card-list':
                return lazyWrapper(
                  <CardList
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'card-list-section':
                return lazyWrapper(
                  <CardListSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />,
                  lazyMode,
                );
              case 'cards-media-section':
                return lazyWrapper(
                  <CardsMediaSection
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                    andesTheme={theme}
                  />,
                  lazyMode,
                );
              case 'filtered-cards':
                return lazyWrapper(
                  <FilteredCards
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-payment-methods':
                return lazyWrapper(
                  <SgsPaymentMethods
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-images-highlight':
                return lazyWrapper(
                  <SgsImagesHighlight
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-animation-component':
                return lazyWrapper(
                  <SgsAnimationComponent
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-slider':
                return lazyWrapper(
                  <SgsSlider
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-accordion-slider':
                return lazyWrapper(
                  <SgsAccordionSlider
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-card-plan':
                return lazyWrapper(
                  <SgsCardPlan
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-hero':
                return lazyWrapper(
                  <SgsHero
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-specs':
                return lazyWrapper(
                  <SgsSpecs
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-multiproduct-selector':
                return lazyWrapper(
                  <SgsMultiproductSelector
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-device-list':
                return lazyWrapper(
                  <SgsDeviceList
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-media-slider':
                return lazyWrapper(
                  <SgsMediaSlider
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-generic-hero':
                return lazyWrapper(
                  <SgsGenericHero
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );
              case 'sgs-message-band':
                return lazyWrapper(
                  <SgsMessageBand
                    key={key}
                    {...config}
                    id={sectionID}
                    isMobile={isMobile}
                    isWebView={isWebView}
                  />,
                  lazyMode,
                );

              default:
                return null;
            }
          })}
        </TrackingProvider>
      </div>

      {gtm && <GTM {...gtm} />}

      {melidata && <MelidataTrack {...melidata} MelidataTrack={experiments} />}

      {/* Options are inferred from frontend-config.
        See https://github.com/mercadolibre/fury_frontend-metrics/tree/master/packages/hotjar#options */}
      {hotjar && !hasRemoteModules && <Hotjar id={hotjar.id} />}

      <Script priority={1}>
        {`window.__PRELOADED_STATE__ = ${serialize(
          {
            experiments,
            imagesPrefix,
            isMobile,
            isWebView,
            landingConfig: {
              groupId,
              variantId,
              versionId,
              variantConfigurationId,
              variantName,
              head,
              productName,
              tracking: {
                gtm,
                melidata,
                hotjar,
              },
              theme,
            },
            locale,
            device,
            scope,
            sections,
            trackHandle,
            mainStyles,
            landingStatus,
            isPreview,
            recaptchaSiteKey,
          },
          { isJSON: true },
        )};`}
      </Script>

      <Script priority={2} src={['vendor.js']} />
    </main>
  );
};

View.propTypes = {
  experiments: PropTypes.shape({}),
  imagesPrefix: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool,
  landingConfig: PropTypes.shape({
    groupId: PropTypes.string,
    variantId: PropTypes.string,
    landingVersionId: PropTypes.string,
    variantConfigurationId: PropTypes.string,
    variantName: PropTypes.string,
    head: PropTypes.shape({ title: PropTypes.string }),
    productName: PropTypes.string,
    theme: PropTypes.string,
    tracking: PropTypes.shape({
      gtm: PropTypes.shape({}),
      melidata: PropTypes.shape({}),
      hotjar: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),
  locale: PropTypes.string,
  device: PropTypes.shape({
    type: PropTypes.string,
    webView: PropTypes.bool,
  }),
  mainStyles: PropTypes.shape({}),
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  scope: PropTypes.string,
  initCallback: PropTypes.func,
  landingStatus: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string,
};

View.defaultProps = {
  sections: [],
  landingConfig: {},
  experiments: {},
  imagesPrefix: '/',
  mainStyles: {},
  scope: '',
  isWebView: false,
  initCallback: noop,
};

module.exports = View;
