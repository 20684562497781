const React = require('react');

const { LazyHydrate } = require('nordic/lazy');

const lazyWrapper = (Component, type) => {
  if (type === 'whenVisible') {
    return <LazyHydrate whenVisible>{Component}</LazyHydrate>;
  }

  if (type === 'ssrOnly') {
    return <LazyHydrate ssrOnly>{Component}</LazyHydrate>;
  }

  return Component;
};

module.exports = lazyWrapper;
